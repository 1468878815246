import * as React from 'react';
import {useAuth} from '../../customHooks/useAuth';

const LogoutCallback = () => {
    const {signoutRedirectCallback} = useAuth()
    signoutRedirectCallback();

    return <span/>
}

export default LogoutCallback;
