import React from 'react';
import './inputCheckbox.scss';

interface IProps {
    text: string;
    containerClassName?: string;
    errorMessage?: string;
    isChecked: boolean;
    onClick: () => void;
}

export const InputCheckbox = ({text, isChecked, onClick, containerClassName = "", errorMessage = ""}: IProps) => {
    return (
        <div className={`checkInput ${containerClassName}`} onClick={() => onClick()}>
            <div className="group">
                <i className={isChecked ? "fas fa-check-square" : "far fa-square"}/>
                <span>{text}</span>
            </div>
            <p>{errorMessage}</p>
        </div>
    );
};