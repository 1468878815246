import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {InputText} from '../../components/inputText/inputText';
import {SsyButton} from '../../components/ssyButton/ssyButton';
import {PartnerService} from '../../services/partnerService';
import {validateStringLength, validateWebsite} from '../../utils/helpers';
import {useForm} from '../../customHooks/useForm';
import {useAuth} from '../../customHooks/useAuth';
import SsySelect from '../../components/ssySelectInput/ssySelectInput';
import {CATEGORY_OPTIONS, LANGUAGES_OPTIONS} from '../../utils/const';
import useLanguage from '../../customHooks/useLanguage';
import useNotification from '../../customHooks/useNotification';

import './settingsPage.scss';
import {IUpdatePartnerRequest, Language, PartnerCategory} from '../../model/partnerModels';

export const SettingsPage = () => {
    const intl = useIntl();
    const {getUserId} = useAuth();
    const {language} = useLanguage();
    const {setNotificationMessageDelayed, setColor} = useNotification();
    const {modifyLanguage} = useLanguage()

    const {
        state: {errors, fields},
        actions
    } = useForm({
        validateFunctions: {
            website: formFields => (!!formFields.website ? validateWebsite(formFields.website, intl) : ''),
            name: formFields => validateStringLength(formFields.name, 3, intl)
        },
        initialState: {
            name: '',
            website: '',
            email: '',
            category: '',
            comission: 0,
            language
        }
    });

    useEffect(() => {
        PartnerService()
            .getPartner(getUserId())
            .then(result => {
                actions.setState(prev => ({
                    name: result.name,
                    email: result.email,
                    website: result.webSite!,
                    category: result.category,
                    language: prev.language,
                    comission: result.commission
                }));
            });
    }, []);

    const submit = () => {
        const updateRequest: IUpdatePartnerRequest = {
            name: fields.name,
            website: fields.website,
            category: fields.category as PartnerCategory,
            language: fields.language as Language
        };
        modifyLanguage(fields.language)
        PartnerService()
            .updatePartner(getUserId(), updateRequest)
            .then(() => {
                // Add confimation modal
                setColor('green');
                setNotificationMessageDelayed(
                    intl.formatMessage({id: 'SETTINGS_PAGE_UPDATE_CONFIRMATION'}),
                    5000
                );
            });
    };

    return (
        <div id="settingsPage">
            <h1>{intl.formatMessage({id: 'LABEL_SETTINGS'})}</h1>
            <div className="settingsForm">
                <h3>{intl.formatMessage({id: 'SETTNGS_PAGE_FORM_TITLE'})}</h3>
                <InputText
                    name="name"
                    label={intl.formatMessage({id: 'LABEL_YOUR_NAME'})}
                    onChange={({target}) => actions.onChangeField(target)}
                    errorMessage={errors.name}
                    value={fields.name}
                />
                <InputText
                    name="website"
                    label={intl.formatMessage({id: 'LABEL_YOUR_WEBSITE'})}
                    onChange={({target}) => actions.onChangeField(target)}
                    errorMessage={errors.website}
                    value={fields.website}
                />
                <InputText
                    name="email"
                    label={intl.formatMessage({id: 'LABEL_EMAIL'})}
                    disabled={true}
                    onChange={({target}) => actions.onChangeField(target)}
                    errorMessage={errors.email}
                    value={fields.email}
                />
                <SsySelect
                    name="category"
                    label={'LABEL_CATEGORY'}
                    onChange={({target}: any) => actions.onChangeField(target)}
                    errorMessage={errors.category}
                    value={fields.category}
                    containerClassName="dark"
                    options={CATEGORY_OPTIONS}
                    intl={intl}
                />
                <SsySelect
                    name="language"
                    label={'LABEL_LANGUAGE_SELECT'}
                    onChange={({target}: any) => actions.onChangeField(target)}
                    errorMessage={errors.language}
                    value={fields.language}
                    containerClassName="dark"
                    options={LANGUAGES_OPTIONS}
                    intl={intl}
                />
                <InputText
                    name="comission"
                    label={intl.formatMessage({id: 'LABEL_COMISSION'})}
                    disabled={true}
                    onChange={({target}) => actions.onChangeField(target)}
                    errorMessage={errors.comission}
                    value={`${fields.comission.toString()}%`}
                />
                <SsyButton label={intl.formatMessage({id: 'LABEL_SAVE'})} onClick={() => actions.submit(submit)}/>
            </div>
        </div>
    );
};
