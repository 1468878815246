import React, {useState} from 'react';
import useModal from '../../../customHooks/useModal';
import {SsyButton} from '../../ssyButton/ssyButton';
import {useIntl} from 'react-intl';
import './inviteLinkModal.scss';
import {InputText} from '../../inputText/inputText';
import {SsyTextArea} from '../../ssyTextArea/ssyTextArea';
import {InviteService} from '../../../services/inviteService'
import {useAuth} from '../../../customHooks/useAuth';
import {IInvite} from '../../../model/inviteModels';
import {validateEmail} from '../../../utils/helpers';
import PartnerLink from "../../partnerLink/partnerLink";
import {IPartner} from "../../../model/partnerModels";

interface IProps {
    onInvite: (invite: IInvite) => void,
    partner: IPartner,
}

export const InviteLinkModal = ({onInvite, partner}: IProps) => {
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerEmailError, setcustomerEmailError] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const {resetModal} = useModal();
    const intl = useIntl();
    const {getUserId} = useAuth()

    const submit = () => {

        const error = validateEmail(customerEmail, intl)
        if (error !== '') {
            setcustomerEmailError(error)
            return
        }

        InviteService().createInvite({
            email: customerEmail,
            inviteText: emailBody,
            partnerId: getUserId(),
            language: intl.locale
        }).then(userInvite => {
            resetModal();
            onInvite(userInvite)
        })
    }

    return (
        <div className="inviteLinkModalWrapper">
            <div className="inviteLinkModalBox">
                <h2>{intl.formatMessage({id: 'INVITE_MODAL_TITLE'})}</h2>

                <div>
                    <InputText
                        label={intl.formatMessage({id: 'INVITE_MODAL_EMAIL_CUSTOMER_EMAIL'})}
                        value={customerEmail}
                        type="email"
                        onChange={e => {
                            if (customerEmailError !== '') {
                                setcustomerEmailError(validateEmail(e.target.value, intl))
                            }
                            setCustomerEmail(e.target.value)
                        }}
                        errorMessage={customerEmailError}
                    />
                    <SsyTextArea
                        name={'emailText'}
                        label={intl.formatMessage({id: 'TEXTAREA_CONTENT'})}
                        placeholder={intl.formatMessage({id: 'INVITE_MODAL_EMAIL_BODY_PLACEHOLDER'})}
                        errorMessage={''}
                        onChange={(value: string) => setEmailBody(value)}
                        value={emailBody}
                        minRows={7}
                    />
                </div>

                <div>
                    <SsyButton
                        type="primary full"
                        label={intl.formatMessage({id: 'LABEL_SEND'})}
                        onClick={() => submit()}
                    />
                    <SsyButton
                        type="secondary full"
                        label={intl.formatMessage({id: 'LABEL_CANCEL'})}
                        onClick={() => resetModal()}
                    />
                </div>
                {
                    partner.linkId &&
                    <hr/> &&
                    <h2>{intl.formatMessage({id: 'INVITE_MODAL_OR_DO_IT'})}</h2> &&
                    <PartnerLink linkId={partner.linkId} dynamicLink={partner.dynamicLink}></PartnerLink>
                }
            </div>
        </div>
    );
};
