import DataSource from './dataSource'
import {ICreateInviteRequest, ICreateInviteRequestResend, IInvite} from '../model/inviteModels'

export const InviteService = () => {
    const createInvite = ({partnerId, ...requestPayload}: ICreateInviteRequest): Promise<IInvite> => {
        return DataSource({
            url: `partners/${partnerId}/invites`,
            method: "POST",
            data: requestPayload,
            config: {
                handleError: false
            }
        })
    }

    const getInvites = (partnerId: string): Promise<IInvite[]> => {
        return DataSource({
            url: `partners/${partnerId}/invites`,
            method: "GET",
            config: {
                handleError: false
            }
        })
    }

    const deleteInvite = (partnerId: string, inviteId: string) => {
        return DataSource({
            url: `partners/${partnerId}/invites/${inviteId}`,
            method: "DELETE",
            config: {
                handleError: false
            }
        })
    }

    const resendInvite = ({partnerId, inviteId}: ICreateInviteRequestResend): Promise<void> => {
        return DataSource({
            url: `partners/${partnerId}/invites/${inviteId}`,
            method: "PUT",
            data: {},
            config: {
                handleError: false
            }
        })
    }
    return {
        createInvite,
        getInvites,
        deleteInvite,
        resendInvite
    }
}