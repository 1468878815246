import React, {useContext} from 'react';
import {ModalContext} from '../providers/modalProvider';
import ConfirmationModal from '../components/modals/confirmationModal/confirmationModal';

export interface IConfirmationModalConfig {
    active: boolean;
    action?: () => void;
    message: string;
    type?: string;
    title: string;
    secondaryButtonLabel?: string;
    primaryButtonLabel?: string;
    isDangerous?: boolean;
}

const useModal = () => {
    const {showModal, setShowModal, resetModal} = useContext(ModalContext);

    const changeBgModal = (bgColor: string) => {
        setShowModal(current => ({...current, bgColor}));
    };
    const showConfirmationModal = ({active, type, ...rest}: IConfirmationModalConfig) => {
        const modalConfig = {
            active,
            content: <ConfirmationModal {...rest} />,
            type
        };
        setShowModal(modalConfig);
    };
    return {
        showModal,
        setShowModal,
        resetModal,
        changeBgModal,
        showConfirmationModal
    };
};

export default useModal;
