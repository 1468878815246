import {useContext} from 'react';
import {NotificationContext} from '../providers/notificationProvider';

const useNotification = () => {
    const {
        progressLevel,
        setProgressLevel,
        infinite,
        setInfinite,
        color,
        setColor,
        setNotificationMessage,
        notificationMessage
    } = useContext(NotificationContext);

    const modifyProgressBar = (currentProgress: number) => {
        setProgressLevel(currentProgress);
    };

    const setNotificationMessageDelayed = (value: string, period: number = 7000) => {
        setNotificationMessage(value);
        setTimeout(() => {
            setNotificationMessage('');
        }, period);
    };

    const copyTextToClipboardWithNotification = (text: string, notificationText: string) => {
        setColor('green');
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text, notificationText);
            return;
        }
        navigator.clipboard.writeText(text).then(
            () => {
                setNotificationMessageDelayed(notificationText);
            },
            err => {
                console.error('Async: Could not copy text: ', err);
            }
        );
    };

    const fallbackCopyTextToClipboard = (text: string, notificationText: string) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            setColor('green');
            setNotificationMessageDelayed(notificationText);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    return {
        modifyProgressBar,
        progressLevel,
        infinite,
        setInfinite,
        color,
        setColor,
        setNotificationMessageDelayed,
        notificationMessage,
        copyTextToClipboardWithNotification
    };
};

export default useNotification;
