import React from 'react';
import {Card} from '../card/card';
import {InfoBlock} from '../infoBlock/infoBlock';
import './eventCard.scss';
import {IInvite} from '../../model/inviteModels';
import {useIntl} from 'react-intl';
import moment from 'moment';

interface IProps {
    invite: IInvite;
    className?: string;
    deleteEvent: () => void
    resendInvite: () => void
}

export const EventCard = ({invite, className = '', deleteEvent, resendInvite}: IProps) => {
    const intl = useIntl();
    const momentFormat = "DD.M.YYYY"
    const upgradedAtDate = new Date(invite.upgradedAt)
    const acceptedAtDate = new Date(invite.acceptedAt)
    const isAccepted = acceptedAtDate.getTime() !== new Date("0001-01-01T00:00:00.0000000+00:00").getTime()
    const isUpgraded = upgradedAtDate.getTime() !== new Date("0001-01-01T00:00:00.0000000+00:00").getTime()
    const bodyMessage = {
        pending: "EVENT_CARD_INVITATION_PENDING",
        accepted: "EVENT_CARD_INVITATION_ACCEPTED",
        upgraded: "EVENT_CARD_INVITATION_UPGRADED"
    }[isUpgraded ? "upgraded" : isAccepted ? "accepted" : "pending"]

    return (
        <Card className={`eventCard ${className}`}>
            <div className="header">
                <h3>{invite.email}</h3>
                <i className={`fas fa-award ${invite.eventInformation?.isPremium ? 'premium' : 'free'}`}/>
                {isUpgraded && <span className="amount">{invite.amount}<span className="currency">{invite.currency}</span></span>}
            </div>
            <div className="body">
                <span>{intl.formatMessage({id: bodyMessage})}</span>
                <span>{isUpgraded
                    ? moment(upgradedAtDate).format(momentFormat)
                    : isAccepted ? moment(acceptedAtDate).format(momentFormat)
                        : ""
                }</span>
            </div>
            <div className="bottom">
                {!!invite.eventInformation && <InfoBlock
                    info={[
                        {label: invite.eventInformation.totalNumberOfUsers, icon: 'fa-users', key: 'numberOfUsers'},
                        {label: invite.eventInformation.totalNumberOfPosts, icon: 'fa-images', key: 'numberOfPost'},
                    ]}
                />}
                {!isAccepted && !isUpgraded && <div className="actions">
                    <i className="fas fa-trash-alt" onClick={() => deleteEvent()}/>
                    <i className="fas fa-paper-plane" onClick={() => resendInvite()}/>
                </div>}
            </div>
        </Card>
    );
};
