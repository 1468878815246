import {useContext} from 'react';
import {AuthContext} from '../providers/authProvider';
import {User, UserManager, WebStorageStateStore} from 'oidc-client';
import {getUrls} from '../utils/helpers';
import {IDENTITY_CONFIG, LANGUAGES, METADATA_OIDC} from '../utils/const';
import {useHistory} from 'react-router';
import ssyStorage from '../services/ssyStorage';

const ClientUserTypeMapper = [
    {
        clientId: "WebApplication",
        userType: "User",
        redirectUrl: `${getUrls('REACT_APP_PUBLIC_URL')}`
    },
    {
        clientId: "WebApplication",
        userType: "RegisteredUser",
        redirectUrl: `${getUrls('REACT_APP_PUBLIC_URL')}`
    },
    {
        clientId: "PartnerWebApplication",
        userType: "Partner",
        redirectUrl: `${getUrls('REACT_APP_PUBLIC_URL')}/partner`
    },
    {
        clientId: "AdminWebApplication",
        userType: "Admin",
        redirectUrl: `${getUrls('REACT_APP_PUBLIC_URL')}`
    }
]
export const useAuth = () => {
    const {userManager, accessToken, userId, idToken} = useContext(AuthContext);
    const history = useHistory();
    let language = 'en';

    if (!userManager || !accessToken || !userId || !idToken) {
        throw Error('There is no auth provider or the current one does not provides the correct fields');
    }

    const getData = () => {
        const localToken = localStorage.getItem('access_token');
        accessToken.current = !!localToken ? localToken : accessToken.current;

        const localUserId = localStorage.getItem('userId');
        userId.current = !!localUserId ? localUserId : userId.current;

        const localIdToken = localStorage.getItem('id_token');
        idToken.current = !!localIdToken ? localIdToken : idToken.current;
    };

    const getRedirectUrl = (): string | false => {
        let redirectUri = ssyStorage.local.getString('redirectUri');
        const redirectParams = ssyStorage.local.getString('redirectParams');
        if (!!redirectUri && redirectUri.indexOf('register') === -1) {
            if (!!redirectParams) {
                redirectUri = redirectUri + redirectParams;
            }
            return redirectUri;
        }
        return false;
    };

    if (!userManager.current) {
        getData();
        let lang = ssyStorage.local.getString('locale');
        if (lang) {
            language = lang
        } else {
            let redirectUri = getRedirectUrl();
            redirectUri = redirectUri ? redirectUri : window.location.pathname;
            const redirectUriArray = redirectUri!.split('/');
            if (window.location.href.indexOf('signin-oidc') < 0) {
                redirectUriArray.forEach(languageItem => {
                    if (LANGUAGES.indexOf(languageItem) !== -1) {
                        language = languageItem;
                    }
                });
            }
        }

        userManager.current = new UserManager({
            ...IDENTITY_CONFIG,
            monitorSession: false,
            userStore: new WebStorageStateStore({store: window.localStorage}),
            metadata: {
                ...METADATA_OIDC,
                authorization_endpoint: METADATA_OIDC.authorization_endpoint + '?culture=' + language
            }
        });

        userManager.current.events.addUserLoaded((user: User) => {
            setData(user);
            if (isFromThePlatform(user.access_token) !== true) {
                window.location.replace(`${getUrls('REACT_APP_URL')}/logout`);
            } else {
                if (window.location.href.indexOf('signin-oidc') !== -1) {
                    navigateToScreen();
                }
            }
        });

        userManager.current.events.addSilentRenewError((_event: any) => {
            console.error('silent renew error', _event.message);
        });

        userManager.current.events.addAccessTokenExpired(() => {
            userManager.current?.signinSilent().catch(e => console.error('Sign in silent error', e));
        });
    }
    const isFromThePlatform = (token?: string | null) => {
        if (!token) {
            return true
        }
        const data = parseJwt(token);
        if (data.usertype !== "Partner") {
            return data.usertype
        }
        return true
    }

    const parseJwt = (token: string) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    };

    const setData = (user: User) => {
        const data = parseJwt(user.access_token);
        accessToken.current = user.access_token;
        userId.current = data.sub;
        idToken.current = user.id_token;

        localStorage.setItem('access_token', accessToken.current);
        localStorage.setItem('id_token', idToken.current);
        localStorage.setItem('userId', userId.current);
    };

    const signinRedirectCallback = () => {
        userManager.current?.signinRedirectCallback().catch(e => console.error('Sign in redirect callback error', e));
    };

    const signinRedirect = () => {
        userManager.current?.signinRedirect().catch(e => console.error('Sign in redirect error', e));
    };

    const navigateToScreen = () => {
        const redirectUri = getRedirectUrl();
        if (!!redirectUri) {
            const _language = redirectUri!.split('/')[1];
            language = _language;
            history.push(redirectUri);
            return;
        }
        history.push(`${language}/dashboard`);
    };

    const isAuthenticated = () => {
        return accessToken.current !== '';
    };

    const signinSilentCallback = () => {
        userManager.current?.signinSilentCallback().catch(e => console.error('Sign in silent callback error', e));
    };

    const redirectHome = () => {
        let lang = ssyStorage.local.getString('locale');
        lang = lang ? lang : 'en';
        const access = ssyStorage.local.getString('access_token')
        const isFromPlatform = isFromThePlatform(access)
        ssyStorage.local.clear();
        if (isFromPlatform !== true) {
            const userClientType = ClientUserTypeMapper.find(item => item.userType === isFromPlatform)
            if (!!userClientType && userClientType.redirectUrl) {
                window.location.replace(userClientType.redirectUrl);
            }
        } else {
            ssyStorage.local.setString('locale', lang);
            const publicHome = `${getUrls('REACT_APP_PUBLIC_URL')}/${lang}`;
            if (publicHome) {
                window.location.replace(publicHome);
            }
        }

    };

    const logout = async () => {
        if (!userManager.current) {
            return;
        }
        const user = await userManager.current.getUser();
        if (!!user) {
            userManager.current.signoutRedirect({
                id_token_hint: idToken.current
            });
            userManager.current.clearStaleState();
        } else {
            redirectHome();
        }
    };

    const signoutRedirectCallback = () => {
        userManager.current?.signoutRedirectCallback().then(() => {
            redirectHome();
        });
        userManager.current?.clearStaleState();
    };

    const createSigninRequest = (args: any = {}) => {
        return userManager.current?.createSigninRequest(args);
    };

    const getUserId = () => userId.current;

    return {
        signinRedirectCallback,
        logout,
        signoutRedirectCallback,
        isAuthenticated,
        signinRedirect,
        signinSilentCallback,
        createSigninRequest,
        getUserId
    };
};
