import React, {useState} from 'react';

interface IContext {
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: IContext = {
    language: '',
    setLanguage: () => ({})
};

export const LanguageContext = React.createContext(initialState);

export const LanguageProvider = (props: any) => {
    const [language, setLanguage] = useState('');

    return (
        <LanguageContext.Provider
            value={{
                language,
                setLanguage
            }}
        >
            {props.children}
        </LanguageContext.Provider>
    );
};
