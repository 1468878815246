import React, {useRef} from 'react';
import './notificationBar.scss';
import useEventListener from '../../customHooks/useEventListener';
import {AnimatePresence, motion} from 'framer-motion';
import {VariantsManager} from '../../utils/variantsManager';
import {isMobile} from '../../utils/helpers';

interface IProps {
    progress: number;
    isInfinite: boolean;
    color: 'red' | 'blue' | 'green';
    notificationMessage: string;
}

const NotificationBar = ({progress, isInfinite, color, notificationMessage}: IProps) => {
    const ref: any = useRef(null);

    const managePosition = () => {
        const newTopDistance = window.scrollY <= 60 && isMobile() ? 50 - window.scrollY : 0;
        if (ref.current && ref.current.style.top !== newTopDistance) {
            ref.current.style.top = `${newTopDistance}px`;
        }
    };
    useEventListener('scroll', managePosition);

    const variants = VariantsManager().notificationVariants();
    return (
        <AnimatePresence>
            <motion.div
                ref={ref}
                id="notificationBar"
                initial="hidden"
                animate={progress > 0 || isInfinite || notificationMessage !== '' ? 'visible' : 'hidden'}
                exit="hidden"
                variants={variants}
                className={`progressBarContainer ${color}`}
            >
                {!!notificationMessage ? (
                    <p id="notificationText">{notificationMessage}</p>
                ) : (
                    <div className="progressBarBack">
                        {!isInfinite ? (
                            <div className="progress" style={{width: `${progress}%`}}/>
                        ) : (
                            <div className="infinite"/>
                        )}
                    </div>
                )}
            </motion.div>
        </AnimatePresence>
    );
};

export default NotificationBar;
