import DataSource from './dataSource'
import {
    IPartnerWithCounters,
    IRegisterPartnerRequest,
    IRegisterPartnerResponse,
    IUpdatePartnerRequest
} from '../model/partnerModels'

export const PartnerService = () => {
    const register = (data: IRegisterPartnerRequest): Promise<IRegisterPartnerResponse> => {
        return DataSource({
            method: 'POST',
            url: 'partners',
            data,
            config: {
                handleError: true
            }
        })
    }

    const getPartner = (partnerId: string): Promise<IPartnerWithCounters> => {
        return DataSource({
            method: "GET",
            url: `partners/${partnerId}`
        })
    }

    const updatePartner = (partnerId: string, request: IUpdatePartnerRequest) => {
        return DataSource({
            method: "PUT",
            url: `partners/${partnerId}`,
            data: request,
            config: {
                handleError: true
            }
        })
    }

    const requestPayout = (partnerId: string) => {
        return DataSource({
            method: "POST",
            url: `partners/${partnerId}/payout`
        })
    }

    return {
        register,
        getPartner,
        updatePartner,
        requestPayout
    }
}