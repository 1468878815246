import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './modal.scss';
import {AnimatePresence, motion} from 'framer-motion';
import {VariantsManager} from '../../utils/variantsManager';

interface IModalChildProps {
    close: () => void;
    open: boolean;
    children: any;
    type: 'opaque' | 'transparent';
    bgColor?: 'blue' | 'yellow' | 'red' | 'green' | 'pink' | 'blue';
}

const Modal = ({children, open, close, type, bgColor}: IModalChildProps & any) => {
    const variants = type === 'transparent' ? VariantsManager().modalFadeIn() : VariantsManager().modalVariants();

    useEffect(() => {
        if (open) {
            window.scrollTo(0, 0);
            window.onpopstate = () => {
                window.history.go(1);
                if (!!close) {
                    close();
                }
            };
        } else {
            window.onpopstate = () => {
            };
        }
    }, [open]);

    return ReactDOM.createPortal(
        <AnimatePresence>
            {!!open && (
                <motion.div
                    animate={'visible'}
                    initial={'hidden'}
                    exit="hidden"
                    variants={variants}
                    className={`modal ${type} ${!open ? 'hidden' : ''} ${bgColor ? bgColor : ''}`}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>,
        document.body
    );
};

export default Modal;
