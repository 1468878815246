export const VariantsManager = () => {
    const raisingBoxVariants = (transition: any) => ({
        hidden: {
            opacity: 0,
            y: 20,
            transition
        },
        show: {
            opacity: 1,
            y: 0,
            transition
        }
    });
    const notificationVariants = () => ({
        hidden: {
            height: 0,
            opacity: 0
        },
        visible: {
            transition: {
                delay: 0.5
            },
            zIndex: 600,
            height: '60px',
            opacity: 1
        }
    });


    const raisingBoxVariantsLeft = (transition: any) => ({
        hidden: {
            opacity: 0,
            width: '100px',
            transition
        },
        show: {
            opacity: 1,
            width: '100%',
            transition
        }
    });

    const modalVariants = () => ({
        hidden: {
            opacity: 0,
            top: '120vh',
            transition: {
                duration: 0.3,
                ease: 'easeOut'
            }
        },
        visible: {
            opacity: 1,
            top: 0,
            transition: {
                duration: 0.3,
                ease: 'easeOut'
            }
        }
    });

    const modalFadeIn = () => ({
        hidden: {
            opacity: 0,
            top: 0,
            transition: {
                duration: 0.3,
                ease: 'easeOut'
            }
        },
        visible: {
            opacity: 1,
            top: 0,
            transition: {
                duration: 0.3,
                ease: 'easeOut'
            }
        }
    });

    return {
        raisingBoxVariants,
        raisingBoxVariantsLeft,
        modalVariants,
        modalFadeIn,
        notificationVariants
    };
};
