import React, {MutableRefObject, ReactNode, useRef} from 'react';
import {UserManager} from 'oidc-client';

interface IAuthContext {
    userManager: MutableRefObject<UserManager | undefined> | undefined
    accessToken: MutableRefObject<string> | undefined,
    userId: MutableRefObject<string> | undefined,
    idToken: MutableRefObject<string> | undefined,
}

interface IAuthProvider {
    children: ReactNode
}

const initialState: IAuthContext = {
    userManager: undefined,
    accessToken: undefined,
    userId: undefined,
    idToken: undefined
}
export const AuthContext = React.createContext(initialState);

export const AuthProvider = ({children}: IAuthProvider) => {
    const userManager = useRef<UserManager | undefined>(undefined)
    const accessToken = useRef("")
    const userId = useRef("")
    const idToken = useRef("")

    return (
        <AuthContext.Provider value={{
            userManager,
            accessToken,
            userId,
            idToken
        }}>
            {children}
        </AuthContext.Provider>
    );
}
