export interface ISsyStorageLocal {
    get: (k: string) => any;
    set: (k: string, v: any) => void;
    setString: (k: string, v: any) => void;
    getString: (k: string) => string | null;
    remove: (k: string) => void;
    clear: () => void;
}

export interface ISsyStorageSession {
    get: (k: string) => any;
    set: (k: string, v: any) => void;
    getString: (k: string) => string | null;
    remove: (k: string) => void;
}

const local: ISsyStorageLocal = {
    get(k: string) {
        try {
            return JSON.parse(localStorage.getItem(k) as string);
        } catch (e) {
            return null;
        }
    },

    set(k: string, v: any) {
        localStorage.setItem(k, JSON.stringify(v));
    },

    setString(k: string, v: any) {
        localStorage.setItem(k, v);
    },

    getString(k: string) {
        try {
            return localStorage.getItem(k);
        } catch (e) {
            return null;
        }
    },
    remove(k: string) {
        try {
            return localStorage.removeItem(k);
        } catch (e) {
            return null;
        }
    },
    clear() {
        try {
            return localStorage.clear();
        } catch (e) {
            return null;
        }
    }
};

const session: ISsyStorageSession = {
    get(k: string) {
        try {
            return JSON.parse(sessionStorage.getItem(k) as string);
        } catch (e) {
            return null;
        }
    },

    set(k: string, v: any) {
        sessionStorage.setItem(k, JSON.stringify(v));
    },

    getString(k: string) {
        try {
            return sessionStorage.getItem(k);
        } catch (e) {
            return null;
        }
    },
    remove(k: string) {
        try {
            return sessionStorage.removeItem(k);
        } catch (e) {
            return null;
        }
    }
};

const ssyStorage = {
    local,
    session
};

export default ssyStorage;
