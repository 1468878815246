import * as React from 'react';
import {Route, RouteProps} from 'react-router-dom';
import {useAuth} from '../customHooks/useAuth';

const PrivateRoute: React.ComponentType<any> = ({component, ...rest}) => {
    const {isAuthenticated, signinRedirect} = useAuth()

    const renderFn = (Component?: React.ComponentType) => (props: RouteProps) => {
        if (!!Component && isAuthenticated()) {
            return (
                <Component {...props} />
            );
        } else {
            signinRedirect();
            return <div className="margin-xl">
                <span>Loading...</span>
            </div>
        }
    }

    return <Route {...rest} render={renderFn(component)}/>;
};

export default PrivateRoute;
