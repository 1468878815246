import React from 'react';
import {injectIntl} from 'react-intl';
import {SsyButton} from '../../ssyButton/ssyButton';
import useModal from '../../../customHooks/useModal';
import './confirmationModal.scss';

interface IProps {
    action?: () => void;
    message: string;
    title: string;
    intl: any;
    secondaryButtonLabel?: string;
    primaryButtonLabel?: string;
    isDangerous?: boolean;
}

const ConfirmationModal = ({
                               action,
                               title,
                               message,
                               intl,
                               secondaryButtonLabel = 'LABEL_NO',
                               primaryButtonLabel = 'LABEL_YES',
                               isDangerous = false
                           }: IProps) => {
    const {resetModal} = useModal();

    return (
        <div className="confirmationModal">
            <div className="inviteLinkModalBox">
                <p className="messageTitle">{intl.formatMessage({id: title})}</p>
                <p className="message">{intl.formatMessage({id: message})}</p>
                <div className="modal-buttons">
                    {action && (
                        <SsyButton
                            label={intl.formatMessage({id: primaryButtonLabel})}
                            onClick={() => {
                                action();
                            }}
                            type={`${!isDangerous ? 'primary' : 'danger'} full`}
                        />
                    )}
                    <SsyButton
                        label={intl.formatMessage({id: secondaryButtonLabel})}
                        onClick={() => resetModal()}
                        type="secondary full"
                    />
                </div>
            </div>
        </div>
    );
};

export default injectIntl(ConfirmationModal);
