export enum PartnerCategory {
    weddingPlanner = "WeddingPlanner",
    photographer = "Photographer",
    others = "Others"
}

export enum Language {
    english = "en",
    german = "de",
    spanish = "es",
    french = "fr",
    portuguese = "pt",
    italian = "it"
}

export interface IPartner {
    id: string,
    email: string,
    name: string,
    webSite?: string,
    category: PartnerCategory,
    eventsPerYear?: number,
    commission: number,
    language: Language,
    linkId: string,
    dynamicLink: string
}

export interface IPartnerWithCounters extends IPartner {
    totalNumberOfInvitesSent: number,
    totalNumberOfPremiumEvents: number,
    totalNumberOfRegisteredEvent: number,
    totalOfPendingCommissions: number,
    totalOfCommissionsEarned: number
}

export const PartnerUserType = 3

export interface IRegisterPartnerRequest {
    name: string,
    website?: string,
    category: PartnerCategory,
    eventsPerYear?: string,
    email: string;
    language: string;
    password: string;
}

export interface IRegisterPartnerResponse {
    api_key: string;
    autologin_token: string;
    Id: string;
    username: string;
}

export interface IUpdatePartnerRequest {
    website: string,
    category: PartnerCategory,
    name: string,
    language: Language
}
