import React, {UIEvent, useEffect, useRef, useState} from 'react'
import './ssySlider.scss'
import {isArray} from 'lodash'

interface ISsySliderProps {
    containerClassName?: string,
    slidesClassname?: string,
    bulletsClassname?: string,
    blockScroll?: boolean,
    blockBullets?: boolean,
    onSlideChange?: (newIndex: number) => void,
    children: ((setSliderPositon: (index: number) => void) => JSX.Element[]) | JSX.Element[]
}

export const SsySlider = ({
                              containerClassName = "",
                              slidesClassname = "",
                              bulletsClassname = "",
                              blockScroll = false,
                              blockBullets = false,
                              onSlideChange,
                              children
                          }: ISsySliderProps) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const scrollableContent = useRef<HTMLUListElement | null>(null)
    const [childrenElements, setChildrenElements] = useState<JSX.Element[]>([])
    const slideWidth = useRef(0)
    useEffect(() => {
        if (!!scrollableContent.current) {
            const totalScroll = scrollableContent.current.scrollWidth
            slideWidth.current = totalScroll / childrenElements.length
        }
    }, [scrollableContent, childrenElements])
    useEffect(() => {
        setChildrenElements(isArray(children) ? children : children(setSliderPosition))
    }, [children])
    useEffect(() => {
        !!onSlideChange && setTimeout(() => onSlideChange(currentIndex), 300);
    }, [currentIndex])
    const setSliderPosition = (index: number) => {
        if (!scrollableContent.current) {
            return
        }
        scrollableContent.current?.scrollTo({
            left: index * slideWidth.current,
            behavior: "smooth"
        })
    }
    const onScollSlider = (event: UIEvent<HTMLUListElement, globalThis.UIEvent>) => {
        if (!scrollableContent.current) {
            return
        }
        const leftScroll = scrollableContent.current.scrollLeft
        const slides = leftScroll / slideWidth.current
        const index = Math.round(slides)
        if (Number(slides.toFixed(1)) === index) {
            setCurrentIndex(index)
        }
    }
    return (
        <div className={`ssySlider ${containerClassName}`}>
            <ul
                className={`sliderContent ${blockScroll ? "scrollblocked" : ""}`}
                ref={scrollableContent}
                onScroll={onScollSlider}
            >
                {childrenElements.map((child, index) => <li key={index} className={`sliderItem ${slidesClassname}`}>
                    {child}
                </li>)}
            </ul>
            <div className="sliderBullets">
                {childrenElements.map((child, childIndex) => <i
                    key={childIndex}
                    className={`${currentIndex === childIndex ? "fas fa-circle" : "far fa-circle"} ${bulletsClassname}`}
                    onClick={() => !blockBullets && setSliderPosition(childIndex)}
                />)}
            </div>
        </div>
    )
}
