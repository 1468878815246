import React, {useEffect, useRef, useState} from 'react';
import './ssyTextArea.scss';

interface IProps {
    className?: string;
    name?: string;
    label?: string;
    errorMessage?: string;
    onChange?: (value: string) => void;
    value?: string;
    placeholder?: string;
    minRows?: number;
    rowHeight?: number;
}

export const SsyTextArea = ({
                                className,
                                label,
                                name,
                                errorMessage,
                                value,
                                onChange,
                                placeholder = "",
                                minRows = 1,
                                rowHeight = 22
                            }: IProps) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const baseScroll = useRef(0);
    const [rows, setRows] = useState(1);
    const [updateRows, setUpdateRows] = useState(false);

    useEffect(() => {
        if (!!textareaRef.current) {
            const value = textareaRef.current.value;
            textareaRef.current.value = '';
            baseScroll.current = textareaRef.current.scrollHeight;
            textareaRef.current.value = value;
        }
    }, [textareaRef]);
    useEffect(() => {
        setRows(
            !!textareaRef.current
                ? Math.ceil((textareaRef.current.scrollHeight - baseScroll.current) / rowHeight) + minRows
                : minRows
        );
    }, [updateRows]);


    const recalculateRows = () => {
        setRows(minRows);
        setUpdateRows(prev => !prev);
    };

    return (
        <div className={`ssyTextAreaContainer ${className}`}>
            <div className="ssyTextArea">
                <textarea
                    ref={ref => (textareaRef.current = ref)}
                    required
                    placeholder={placeholder}
                    name={name}
                    value={value ? value : ''}
                    onChange={e => {
                        recalculateRows();
                        if (!!onChange) {
                            onChange(e.target.value);
                        }
                    }}
                    rows={minRows}
                />
                {!!label && <label>{label}</label>}
                {!!errorMessage && <p>{errorMessage}</p>}
            </div>
        </div>
    );
};
