import React from 'react';
import './inputText.scss';

interface IProps {
    value: string;
    onChange: (e: any) => void;
    label: string;
    containerClassName?: string,
    required?: boolean,
    name?: string;
    type?: string;
    onBlur?: () => void;
    errorMessage?: string;
    disabled?: boolean
}

export const InputText = ({
                              value,
                              onChange,
                              name,
                              label,
                              onBlur,
                              errorMessage,
                              containerClassName,
                              required,
                              disabled,
                              type = 'text'
                          }: IProps) => {
    return (
        <div className={`inputContainer ${containerClassName}`}>
            <div className="group">
                <input
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            //@ts-ignore
                            e.target.blur();
                        }
                    }}
                    disabled={disabled}
                    onBlur={!!onBlur ? () => onBlur() : () => ({})}
                    type={type}
                    autoComplete={!!name ? `new-${name}` : undefined}
                    required={required}
                    value={value}
                    name={name}
                    onChange={onChange}
                />
                <span className="bar"/>
                <label>{label}</label>
            </div>
            <p className="input-error">{errorMessage}</p>
        </div>
    );
};
