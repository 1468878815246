import React, {useState} from 'react';
import './inputPassword.scss';

interface IProps {
    value: string;
    onChange: (e: any) => void;
    label: string;
    containerClassName?: string,
    required?: boolean;
    name?: string;
    onBlur?: () => void;
    errorMessage?: string;
    strenthEvaluable?: boolean;
}

const InputPassword = ({value, onChange, name, label, errorMessage, onBlur, required, containerClassName = "", strenthEvaluable = false}: IProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [securityLevel, setSecurityLevel] = useState(0);
    const onInputChange = (e: any) => {
        onChange(e);
        if (strenthEvaluable) {
            const password = e.target.value;
            const nextSecurityLevel =
                password.length === 0
                    ? 0
                    : password.length >= 6 && password.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/)
                        ? 3
                        : password.length >= 6
                            ? 2
                            : 1;
            setSecurityLevel(nextSecurityLevel);
        }
    };

    return (
        <div className={`inputContainerPassword ${containerClassName}`}>
            <div className="group">
                <input
                    type={isVisible ? 'text' : 'password'}
                    autoComplete={!!name ? `new-${name}` : undefined}
                    required={required}
                    value={value}
                    name={name}
                    onChange={e => onInputChange(e)}
                    onBlur={!!onBlur ? () => onBlur() : () => ({})}
                />
                <span className="bar"/>
                {strenthEvaluable && (
                    <div className="barContainer">
                        <span className={`bar1 ${securityLevel >= 1 ? 'active' : ''}`}/>
                        <span className={`bar2 ${securityLevel >= 2 ? 'active' : ''}`}/>
                        <span className={`bar3 ${securityLevel >= 3 ? 'active' : ''}`}/>
                    </div>
                )}
                <i
                    className={`fad ${!isVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                    onClick={value.length > 0 ? () => setIsVisible(!isVisible) : () => ({})}
                />
                <label>{label}</label>
            </div>
            {!!errorMessage && <p className="input-error">{errorMessage}</p>}
        </div>
    );
};

export default InputPassword;
