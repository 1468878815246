import {PartnerCategory} from '../model/partnerModels';
import {getUrls} from './helpers';

export const IDENTITY_CONFIG = {
    authority: getUrls("REACT_APP_AUTH_URL"),
    client_id: getUrls("REACT_APP_IDENTITY_CLIENT_ID"),
    redirect_uri: getUrls("REACT_APP_REDIRECT_URL"),
    silent_redirect_uri: getUrls("REACT_APP_SILENT_REDIRECT_URL"),
    post_logout_redirect_uri: getUrls("REACT_APP_LOGOFF_REDIRECT_URL"),
    audience: 'https://api.samsaidyes.com',
    response_type: 'id_token token',
    automaticSilentRenew: false,
    loadUserInfo: false,
    scope: 'openid ssy.api ssy.partner.api profile'
};
export const LANGUAGES = ['es', 'it', 'en', 'pt', 'de', 'fr'];

export const METADATA_OIDC = {
    issuer: getUrls("REACT_APP_IDENTITY_ISSUER"),
    jwks_uri: getUrls("REACT_APP_AUTH_URL") + '/.well-known/openid-configuration/jwks',
    authorization_endpoint: getUrls("REACT_APP_AUTH_URL") + '/connect/authorize',
    token_endpoint: getUrls("REACT_APP_AUTH_URL") + '/connect/token',
    userinfo_endpoint: getUrls("REACT_APP_AUTH_URL") + '/connect/userinfo',
    end_session_endpoint: getUrls("REACT_APP_AUTH_URL") + '/connect/endsession',
    check_session_iframe: getUrls("REACT_APP_AUTH_URL") + '/connect/checksession',
    revocation_endpoint: getUrls("REACT_APP_AUTH_URL") + '/connect/revocation',
    introspection_endpoint: getUrls("REACT_APP_AUTH_URL") + '/connect/introspect',
};

export const URL = (language = 'en'): any => {
    return {
        dashboard: `/${language}/dashboard`,
        register: `/${language}/register`,
        settings: `/${language}/settings`,
        logout: `/logout`,
        payout: `/${language}/payout`,
    };
};

export const CATEGORY_OPTIONS = [
    {label: 'LABEL_OTHERS', value: PartnerCategory.others},
    {label: 'LABEL_PHOTOGRAPHER', value: PartnerCategory.photographer},
    {label: 'LABEL_WEDDING_PLANNER', value: PartnerCategory.weddingPlanner}
];

export const NUMBER_EVENTS_OPTIONS = [
    {label: '1-10', value: 1},
    {label: '10-50', value: 10},
    {label: 'LABEL_MORE_THAN_50', value: 50}
];

export const LANGUAGES_OPTIONS = [
    {value: 'en', label: 'OPTION_LANGUAGE_EN'},
    {value: 'de', label: 'OPTION_LANGUAGE_DE'},
    {value: 'it', label: 'OPTION_LANGUAGE_IT'},
    {value: 'es', label: 'OPTION_LANGUAGE_ES'},
    {value: 'fr', label: 'OPTION_LANGUAGE_FR'},
    {value: 'pt', label: 'OPTION_LANGUAGE_PT'},
    {value: 'tr', label: 'OPTION_LANGUAGE_TR'}
];