import React, {useEffect} from 'react';
import {IntlProvider} from 'react-intl';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from './providers/authProvider';
import {Routes} from './routes/routes';
import enTranslations from './translations/en.json';
import deTranslations from './translations/de.json';
import frTranslations from './translations/fr.json';
import esTranslations from './translations/es.json';
import itTranslations from './translations/it.json';
import ptTranslations from './translations/pt.json';
import trTranslations from './translations/tr.json';

import moment from 'moment';
import {getLanguageFromURL} from './utils/helpers';
import useLanguage from './customHooks/useLanguage';

export const App = () => {
    let translations: any;
    translations = {
        de: deTranslations,
        en: enTranslations,
        es: esTranslations,
        fr: frTranslations,
        it: itTranslations,
        pt: ptTranslations,
        tr: trTranslations,
    };

    const {language, modifyLanguage} = useLanguage();
    useEffect(() => {
        let newLanguage = getLanguageFromURL(window.location.pathname);
        newLanguage = newLanguage ? newLanguage : 'en';
        modifyLanguage(newLanguage);
        moment.locale(newLanguage);
    }, []);

    const fragment = (props: any) => {
        return props.children;
    };

    const merge = (targetLanguage: any, fallbackLanguage: any) => {
        for (const key in targetLanguage) {
            if (targetLanguage[key] === '') {
                targetLanguage[key] = fallbackLanguage[key];
            }
        }
        return targetLanguage;
    };
    const languageSelected = language ? translations[language] : {};
    if (!language) {
        return <div></div>;
    }
    return (
        <AuthProvider>
            <IntlProvider
                textComponent={fragment}
                locale={language}
                messages={merge(languageSelected, translations.en)}
            >
                <BrowserRouter children={Routes(language)} basename={'/'}/>
            </IntlProvider>
        </AuthProvider>
    );
};
