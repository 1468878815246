import React from 'react'
import './infoBlock.scss'


interface IProps {
    info: Array<{
        icon: string,
        label: string | number,
        key?: string
    }>,
}

export const InfoBlock = ({info}: IProps) => {
    return (
        <div className="infoBlock">
            {info.map(({icon, label, key}) => <div key={key ? key : `${icon}/${label}`}>
                <i className={`fas ${icon}`}/>
                <span>{label}</span>
            </div>)}
        </div>
    )
}