import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {VariantsManager} from '../../utils/variantsManager';
import {isMobile} from '../../utils/helpers';

interface IProps {
    children: any;
    delayOrder: number;
    quantityOfItems: number;
    duration?: number;
    delay?: number;
    id?: string;
    action?: () => void;
    type?: string;
    y?: number;
    x?: number;
    appearMode?: 'horizontal' | 'vertical';
}

export const RaisingBox = ({
                               children,
                               quantityOfItems,
                               delayOrder, // order of appearance
                               duration = 1,
                               delay,
                               action,
                               type = '',
                               appearMode = 'vertical',
                               id = '',
                               y = 20
                           }: IProps) => {
    const transition = {
        duration,
        delay: delay ? delay : quantityOfItems > 1 ? delayOrder / quantityOfItems : 0.2,
        ease: 'easeOut'
    };

    return (
        <AnimatePresence>
            <motion.div
                onClick={!!action ? () => action() : () => ({})}
                className={`raising-box ${type}`}
                initial="hidden"
                animate={'show'}
                exit="hidden"
                variants={
                    appearMode === 'horizontal' && !isMobile()
                        ? VariantsManager().raisingBoxVariantsLeft(transition)
                        : VariantsManager().raisingBoxVariants(transition)
                }
                id={id}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
};
