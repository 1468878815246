import React, {useEffect, useState} from 'react';
import './dashboardPage.scss';
import {SsyButton} from '../../components/ssyButton/ssyButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useIntl} from 'react-intl';
import {Spinner} from '../../components/spinner/spinner';
import {EventCard} from '../../components/eventCard/eventCard';
import useModal from '../../customHooks/useModal';
import {IShowModal} from '../../providers/modalProvider';
import {InviteLinkModal} from '../../components/modals/inviteLinkModal/inviteLinkModal';
import {IndicatorList, IPropsInidicatorList} from '../../components/indicatorList/indicatorList';
import {IInvite} from '../../model/inviteModels';
import {InviteService} from '../../services/inviteService';
import {useAuth} from '../../customHooks/useAuth';
import {PartnerService} from '../../services/partnerService';
import {IPartner, IPartnerWithCounters} from '../../model/partnerModels';
import useNotification from '../../customHooks/useNotification';
import useLanguage from '../../customHooks/useLanguage';
import PartnerLink from "../../components/partnerLink/partnerLink";

export const DashboardPage = () => {
    const intl = useIntl();
    const {setShowModal, showConfirmationModal, resetModal} = useModal();
    const [invites, setInvites] = useState<IInvite[]>([]);
    const [partnerInfo, setPartnerInfo] = useState<IPartnerWithCounters>();
    const {getUserId} = useAuth();
    const {setNotificationMessageDelayed, setColor} = useNotification();
    const {modifyLanguage} = useLanguage()
    const partnerId = getUserId();


    useEffect(() => {
        PartnerService()
            .getPartner(partnerId)
            .then(result => {
                setPartnerInfo(result)
                modifyLanguage(result.language)
            });
    }, [invites]);

    useEffect(() => {
        InviteService()
            .getInvites(partnerId)
            .then(result => setInvites(result));
    }, [partnerId]);


    useEffect(() => {
        const interval = setInterval(() => {

            console.info("fetching new stats ..")
            InviteService()
                .getInvites(partnerId)
                .then(result => setInvites(result));

            PartnerService()
                .getPartner(partnerId)
                .then(result => {
                    setPartnerInfo(result)
                    modifyLanguage(result.language)
                });

        }, 15000);
        return () => {
            console.info("stopped ..")
            clearInterval(interval);
        };
    }, []);


    const showInviteModal = () => {

        const showModalConf: IShowModal = {
            active: true,
            content: <InviteLinkModal
                onInvite={(newInvite: IInvite) => {
                    if (partnerInfo) {
                        setPartnerInfo(current => current ? {
                            ...current,
                            totalNumberOfInvitesSent: current.totalNumberOfInvitesSent + 1
                        } : current)
                    }
                    setInvites(current => [newInvite, ...current])
                }
                }
                partner={partnerInfo as IPartner}/>,
            type: 'transparent'
        };
        setShowModal(showModalConf);
    };

    const deleteEvent = (id: string) => {
        const modalConfig = {
            active: true,
            isDangerous: true,
            type: 'transparent',
            title: 'DELETE_EVENT_MODAL_TITLE',
            message: 'DELETE_EVENT_MODAL_MESSAGE',
            action: () => {
                InviteService()
                    .deleteInvite(getUserId(), id)
                    .then(() => {
                        const nextState = invites.filter(item => item.id !== id);
                        setInvites(nextState);
                        resetModal()
                    });
            }
        };
        showConfirmationModal(modalConfig);
    };


    const resendInvite = (inviteId: string) => {
        const partnerId = getUserId();

        const modalConfig = {
            active: true,
            isDangerous: false,
            type: 'transparent',
            title: 'RESEND_INVITE_MODAL_TITLE',
            message: 'RESEND_INVITE_MODAL_MESSAGE',
            action: () => {
                InviteService()
                    .resendInvite({inviteId, partnerId})
                    .then(() => {
                        setColor('green');
                        setNotificationMessageDelayed(
                            intl.formatMessage({id: 'RESEND_INVITE_CONFIRMATION'}),
                            5000
                        );
                        resetModal()
                    });
            }
        };
        showConfirmationModal(modalConfig);
    };


    const currency = 'USD';
    const indicatorElements: IPropsInidicatorList['elements'] = [
        {
            label: intl.formatMessage({id: 'DASHBOARD_INDICATOR_INVITATIONS'}),
            value: partnerInfo ? partnerInfo.totalNumberOfInvitesSent : 0
        },
        {
            label: intl.formatMessage({id: 'DASHBOARD_INDICATOR_EVENTS'}),
            value: partnerInfo ? partnerInfo.totalNumberOfRegisteredEvent : 0
        },
        {
            label: intl.formatMessage({id: 'DASHBOARD_INDICATOR_PREMIUM'}),
            value: partnerInfo ? partnerInfo.totalNumberOfPremiumEvents : 0
        },
        {
            label: intl.formatMessage({id: 'DASHBOARD_INDICATOR_COMMISSIONS_TOTAL'}),
            value: partnerInfo ? partnerInfo.totalOfCommissionsEarned : 0,
            currency
        },
        {
            label: intl.formatMessage({id: 'DASHBOARD_INDICATOR_COMMISSIONS_PERIOD'}),
            value: partnerInfo ? partnerInfo.totalOfPendingCommissions : 0,
            currency
        }
    ];


    return (
        <div className="container">
            <h1>{intl.formatMessage({id: 'DASHBOARD_INDICATOR_WELCOME'})}</h1>
            <IndicatorList
                containerClassName="dashboardInidicator"
                elements={indicatorElements}
                disabledText={intl.formatMessage({id: 'LABEL_COMING_SOON'})}
            />

            {partnerInfo != undefined &&
                <PartnerLink linkId={partnerInfo.linkId} dynamicLink={partnerInfo.dynamicLink}></PartnerLink>
            }
            <SsyButton
                label={intl.formatMessage({id: 'DASHBOARD_INVITE_CUSTOMER'})}
                onClick={() => showInviteModal()}
                type="nonBorder full"
            />
            <InfiniteScroll
                className="eventScroll"
                dataLength={invites?.length}
                next={() => ({})}
                hasMore={false}
                scrollThreshold="1000px"
                scrollableTarget="mainContent"
                loader={
                    <div className="spinerBlock">
                        <Spinner/>
                    </div>
                }
            >
                {
                    invites &&
                    Array.isArray(invites) &&
                    invites.map(invite => (
                        <EventCard
                            className="dashboardEventCard"
                            key={invite.id}
                            invite={invite}
                            deleteEvent={() => deleteEvent(invite.id)}
                            resendInvite={() => resendInvite(invite.id)}

                        />
                    ))}
            </InfiniteScroll>
        </div>
    );
};
