import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import React from 'react';
import './ssySelectInput.scss';

const customTheme = createTheme({
    palette: {
        primary: {
            dark: '#1c6a8c',
            light: '#1c6a8c',
            main: '#1c6a8c'
        }
    }
});

const SsySelect = ({value, onChange, options, label, name, intl, containerClassName = ""}: any) => {
    return (
        <div className={`${containerClassName} selectContainer`}>
            <InputLabel htmlFor={name}>{intl.formatMessage({id: label})}</InputLabel>

            <MuiThemeProvider theme={customTheme}>
                <Select
                    value={value}
                    className="languageSelector"
                    onChange={(e: any) => onChange(e)}
                    inputProps={{
                        id: name,
                        name
                    }}
                >
                    {options.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label.indexOf('_') !== -1 ? intl.formatMessage({id: option.label}) : option.label}
                        </MenuItem>
                    ))}
                </Select>
            </MuiThemeProvider>
        </div>
    );
};

export default SsySelect;
