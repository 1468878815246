import React, {ReactNode} from 'react'
import './card.scss'

interface IProps {
    children: ReactNode,
    className?: string
}

export const Card = ({children, className = ""}: IProps) => {
    return (
        <div className={`ssy-card ${className}`}>
            {children}
        </div>
    )
}