import {isFunction} from "lodash"
import React, {ChangeEvent, useReducer} from "react"

interface IFormState<FormFields> {
    fields: FormFields,
    errors: {
        [key in keyof Partial<FormFields>]: string
    },
    isSubmited: boolean
}

interface IActions<FormFields> {
    setState: ((prev: FormFields) => FormFields) | FormFields,
    onChangeField: ChangeEvent<HTMLInputElement>["target"],
    submit: (() => void)
}

interface IAction<FormFields, T extends keyof IActions<FormFields>> {
    type: T,
    payload: IActions<FormFields>[T]
}

interface IUseFormProps<FormFields> {
    initialState: FormFields
    validateFunctions: {
        [key in keyof Partial<FormFields>]: (fields: FormFields) => string
    }
}

export const useForm = <FormFields extends { [x: string]: any }>({validateFunctions, initialState}: IUseFormProps<FormFields>) => {
    const [formState, actions] = useReducer(<T extends keyof IActions<FormFields>>(state: IFormState<FormFields>, action: IAction<FormFields, T>) => {
        switch (action.type) {
            case "onChangeField":
                const target = action.payload as IAction<FormFields, "onChangeField">["payload"]
                const fieldName = target.name as keyof FormFields

                if (Object.keys(state.fields).findIndex(key => key === fieldName) !== -1) {
                    state.fields[fieldName] = target.value as any

                    if (state.isSubmited) {
                        if (!!validateFunctions[fieldName]) {
                            state.errors[fieldName] = validateFunctions[fieldName]!(state.fields) as any
                        }
                    }
                }
                break;

            case "setState":
                const newState = action.payload as IAction<FormFields, "setState">["payload"]
                let newFields

                if (isFunction(newState)) {
                    newFields = newState(state.fields)
                } else {
                    newFields = newState
                }
                state.fields = newFields
                break;

            case "submit":
                const submit = action.payload as IAction<FormFields, "submit">["payload"]
                state.isSubmited = true
                let valid = true

                state.errors = Object.keys(validateFunctions).reduce((acum, key) => {
                    acum[key] = validateFunctions[key as keyof FormFields]!(state.fields)
                    if (!!acum[key]) {
                        valid = false
                    }
                    return acum
                }, {} as any)

                if (valid) {
                    submit();
                }
                break;

            default:
                console.error("Trying an invalid action at useForm")
                break;
        }
        return {...state};
    }, {
        fields: initialState,
        errors: {} as any,
        isSubmited: false
    })

    const {isSubmited, ...rest} = formState
    return {
        state: rest, actions: {
            setState: (newState: ((prev: FormFields) => FormFields) | FormFields) => actions({
                type: "setState",
                payload: newState
            }),
            onChangeField: (target: ChangeEvent<HTMLInputElement>["target"]) => actions({
                type: "onChangeField",
                payload: target
            }),
            submit: (func: () => void) => actions({
                type: "submit",
                payload: func
            }),
        }
    }
}