import React from 'react';
import {useAuth} from '../../customHooks/useAuth'

const Callback = () => {
    const {signinRedirectCallback} = useAuth()
    signinRedirectCallback();

    return <span/>
}

export default Callback;
