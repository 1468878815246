import React from 'react'
import './indicatorList.scss'

export interface IPropsInidicatorList {
    elements: Array<{ value: number, label: string, currency?: string }>,
    containerClassName?: string,
    disabledText?: string
}

export const IndicatorList = ({elements, containerClassName = ""}: IPropsInidicatorList) => {
    return (
        <ul className={`inidicatorList ${containerClassName}`}>
            {elements.map(({value, label, currency}) => <li key={label}>
                <h2>
                    {Math.trunc(value) === value ? value : value?.toFixed(2)}
                    {!!currency ? <span>{currency}</span> : ''}
                </h2>
                <span>{label}</span>
            </li>)}
        </ul>
    )
} 
