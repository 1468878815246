import * as React from 'react';
import {useAuth} from '../../customHooks/useAuth';

const Logout = () => {
    const {logout} = useAuth()
    logout()

    return <span/>
}

export default Logout;
