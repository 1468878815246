import axios, {AxiosRequestConfig} from 'axios';
import {getUrls} from '../utils/helpers';

const client = axios.create({
    baseURL: getUrls('REACT_APP_ROOT_URL'),
    maxRedirects: 0,
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin': '*',
    }
});

interface IConfig {
    handleError: boolean
}

const dataSource = (options: AxiosRequestConfig & { config?: IConfig }) => {
    const token = localStorage.getItem('access_token')
    options.headers = {
        Authorization: !!token ? `Bearer ${token}` : "",
        ...options.headers,
        'api-version': '1.0'
    };
    const onSuccess = (response: any) => {
        return response.data;
    };

    const onError = (error: any) => {
        if (!!error && error.response) {
            // Request was made but server responded with something
            // other than 2xx
            if (error.response.status === 403 || error.response.status === 401) {
                window.location.replace('/logout');
            }
        } else {
            // Something else happened while setting up the request
            // triggered the error
        }

        return Promise.reject(error.response || error.message);
    };

    if (options.config && !options.config.handleError) {
        return client(options).then(onSuccess);
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default dataSource;
