import React, {useEffect, useState} from 'react';
import {ProgressBar} from '../../components/progressBar/progressBar';
import {useIntl} from 'react-intl';
import './payoutPage.scss';
import {IndicatorList} from '../../components/indicatorList/indicatorList';
import {PartnerService} from '../../services/partnerService';
import {useAuth} from '../../customHooks/useAuth';
import {SsyButton} from '../../components/ssyButton/ssyButton';
import {useHistory} from 'react-router';
import useNotification from '../../customHooks/useNotification';

export const PayoutPage = () => {
    const intl = useIntl();
    const [balance, setBalance] = useState(0);
    const payoutMinimum = 100;
    const {getUserId} = useAuth();
    const reachedPayoutAmount = balance >= payoutMinimum;
    const history = useHistory();
    const {setNotificationMessageDelayed, setColor} = useNotification();

    useEffect(() => {
        PartnerService()
            .getPartner(getUserId())
            .then(res => {
                setBalance(res.totalOfPendingCommissions);
            });
    }, []);

    const requestPayout = () => {
        PartnerService()
            .requestPayout(getUserId())
            .then(() => {
                setColor('green');
                setNotificationMessageDelayed(
                    intl.formatMessage({id: 'REQUEST_PAYOUT_CONFIRMATION'}),
                    5000
                );
                history.push('/events');
            });
    };
    return (
        <div id="payoutPage">
            <h1>{intl.formatMessage({id: 'LABEL_PAYOUT'})}</h1>
            <p className={reachedPayoutAmount ? 'reachedText' : ''}>
                {reachedPayoutAmount
                    ? intl.formatMessage({id: 'REQUEST_PAYOUT_REACHED_TEXT'})
                    : intl.formatMessage({id: 'REQUEST_PAYOUT_TEXT'})}
            </p>
            <IndicatorList
                containerClassName="indicatorBalance"
                elements={[
                    {label: intl.formatMessage({id: 'LABEL_CURRENT_BALANCE'}), value: balance, currency: 'USD'}
                ]}
            />
            {!reachedPayoutAmount && (
                <ProgressBar
                    className="payoutProgress"
                    progress={balance}
                    max={payoutMinimum}
                    label={intl.formatMessage({id: 'REQUEST_PAYOUT_PROGRESS_TEXT'}, {progress: balance})}
                />
            )}
            {reachedPayoutAmount && (
                <SsyButton label={intl.formatMessage({id: 'LABEL_PAYOUT'})} onClick={() => requestPayout()}/>
            )}
        </div>
    );
};
