import React, {useState} from 'react';

interface IContext {
    progressLevel: number;
    setProgressLevel: React.Dispatch<React.SetStateAction<number>>;
    infinite: boolean;
    setInfinite: React.Dispatch<React.SetStateAction<boolean>>;
    color: 'red' | 'blue' | 'green';
    setColor: React.Dispatch<React.SetStateAction<'red' | 'blue' | 'green'>>;
    notificationMessage: string;
    setNotificationMessage: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: IContext = {
    progressLevel: 0,
    setProgressLevel: () => ({}),
    infinite: false,
    setInfinite: () => ({}),
    color: 'blue',
    setColor: () => ({}),
    notificationMessage: '',
    setNotificationMessage: () => ({})
};

export const NotificationContext = React.createContext(initialState);

export const NotificationProvider = (props: any) => {
    const [progressLevel, setProgressLevel] = useState(0);
    const [infinite, setInfinite] = useState(false);
    const [color, setColor] = useState<'red' | 'blue' | 'green'>('blue');
    const [notificationMessage, setNotificationMessage] = useState('');

    return (
        <NotificationContext.Provider
            value={{
                progressLevel,
                setProgressLevel,
                infinite,
                setInfinite,
                color,
                setColor,
                notificationMessage,
                setNotificationMessage
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
};
