import React, { useState } from 'react';
import './header.scss';
import logo from '../../assets/images/ssy-logo.svg';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { URL } from '../../utils/const';
import { isMobile, isTablet } from '../../utils/helpers';
import useEventListener from '../../customHooks/useEventListener';
import useLanguage from '../../customHooks/useLanguage';

export const Header = () => {
    const intl = useIntl()
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [isDesktop, setIsDesktop] = useState(!isMobile() && !isTablet())
    const {language} = useLanguage();

    useEventListener('resize', e => setIsDesktop(!isMobile() && !isTablet()))

    const navItems = [
        {label: intl.formatMessage({id: "LABEL_PAYOUT"}), uri: URL(language).payout},
        {label: intl.formatMessage({id: "LABEL_SETTINGS"}), uri: URL(language).settings},
        {label: intl.formatMessage({id: "LABEL_LOGOUT"}), uri: URL(language).logout}
    ]

    return (
        <header className={`mainHeader ${isOpen ? "opened" : "closed"}`}>
            <div className="menuHeader">
                <div className="logoContainer" onClick={() => history.push(URL(language).dashboard)}>
                    <img className="logo" src={logo}/>
                    <h3>{intl.formatMessage({id: "LABEL_PARTNER_PORTAL"})}</h3>
                </div>
                {!isDesktop && <i
                    className="menuIcon fas fa-2x fa-bars"
                    onClick={() => setIsOpen(prev => !prev)}
                />}
            </div>
            <nav className={isOpen ? "opened" : "closed"}>
                <ul>
                    {navItems.map(({ label, uri }) => <li key={uri} onClick={() => {
                        setIsOpen(false);
                        history.push(uri);
                    }}>
                        {label}
                    </li>)}
                </ul>
            </nav>
        </header>
    );
};
