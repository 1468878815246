import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Callback from '../page/idp/callback';
import Logout from '../page/idp/logout';
import {ModalProvider} from '../providers/modalProvider';
import {NotificationProvider} from '../providers/notificationProvider';
import {RegisterPage} from "../page/registerPage/registerPage";
import LogoutCallback from "../page/idp/logoutCallback";
import SilentRenew from "../page/idp/silentRenew";
import Layout from "../containers/layout/layout";
import PrivateRoute from "./privateRoute";
import {DashboardPage} from "../page/dashboardPage/dashboardPage";
import {PayoutPage} from "../page/payoutPage/payoutPage";
import {SettingsPage} from "../page/settingsPage/settingsPage";

export const Routes = (lang: string) => {
    return (
        <React.Fragment>
            <NotificationProvider>
                <ModalProvider>
                    <Switch>
                        <Route exact={true} path="/:lng(en|es|de|fr|pt|it|tr)/register" component={RegisterPage}/>
                        <Route exact={true} path="/signin-oidc" component={Callback}/>
                        <Route exact={true} path="/logout" component={Logout}/>
                        <Route exact={true} path="/logout/callback" component={LogoutCallback}/>
                        <Route exact={true} path="/silentrenew" component={SilentRenew}/>
                        <Layout>
                            <Switch>
                                <PrivateRoute
                                    path="/:lng(en|es|de|fr|pt|it|tr)/dashboard"
                                    component={() => <DashboardPage/>}
                                />
                                <PrivateRoute path="/:lng(en|es|de|fr|pt|it|tr)/payout" component={() => <PayoutPage/>}/>
                                <PrivateRoute
                                    path="/:lng(en|es|de|fr|pt|it|tr)/settings"
                                    component={() => <SettingsPage/>}
                                />
                                <Redirect exact={true} from="/*" to={`/${lang}/dashboard`}/>
                            </Switch>
                        </Layout>
                    </Switch>
                </ModalProvider>
            </NotificationProvider>
        </React.Fragment>
    );
};
