import React, {useEffect, useRef, useState} from "react"
import './progressBar.scss'

interface IProgressBarProps {
    label?: string,
    progress: number,
    max?: number,
    className?: string,
}

export const ProgressBar = ({progress, label, className = "", max = 100}: IProgressBarProps) => {
    const id = useRef(Math.floor(Math.random() * 100000).toString(16).substring(1))
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const newWidth = (progress / max) * 100;
        setWidth(newWidth > max ? max : newWidth)
    }, [progress])

    return (
        <div className={`progressBar ${className}`}>
            {label && <label htmlFor={id.current}>{label}</label>}
            <span id={id.current} className="progressBarBackground">
                <span style={{width: `${width}%`}} className="progressBarValue"/>
            </span>
        </div>
    )
}