import React from 'react';
import {Header} from '../../components/header/header';
import './layout.scss';
import Modal from '../modal/modal';
import useModal from '../../customHooks/useModal';
import useNotification from '../../customHooks/useNotification';
import NotificationBar from '../../components/notificationBar/notificationBar';

interface IProps {
    children: JSX.Element;
}

const Layout = ({children}: IProps) => {
    const {showModal, resetModal} = useModal();
    const {progressLevel, infinite, color, notificationMessage} = useNotification();

    return (
        <>
            <Header/>
            <NotificationBar
                notificationMessage={notificationMessage}
                progress={progressLevel}
                isInfinite={infinite}
                color={color}
            />
            <div className="mainContainer">{children}</div>
            <Modal bgColor={showModal.bgColor} type={showModal.type} open={showModal.active} close={() => resetModal()}>
                {showModal.content}
            </Modal>
        </>
    );
};

export default Layout;
