import debounce from 'lodash/debounce';
import React from 'react';
import './ssyButton.scss';

export interface ISsyButton {
    label: string | JSX.Element;
    onClick: () => void;
    type?: string;
    icon?: string;
}

export const SsyButton = ({label, onClick, icon, type = 'primary'}: ISsyButton): JSX.Element => {
    return (
        <button
            className={`button ${type}`}
            onClick={debounce(() => onClick(), 300, {
                leading: true,
                trailing: false
            })}
        >
            {icon && <i className={icon}/>} {label}
        </button>
    );
};
