import * as React from 'react';
import {useAuth} from '../../customHooks/useAuth';

const SilentRenew = () => {
    const {signinSilentCallback} = useAuth()
    signinSilentCallback()

    return <span/>
};

export default SilentRenew;
