import * as React from 'react';
import './spinner.scss';

interface IProps {
    className?: string;
}

export const Spinner = ({className}: IProps): JSX.Element => {
    return (
        <div className={`vp-roller-container ${className ? className : ''}`}>
            <div className="vp-roller">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
};
