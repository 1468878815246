import {useContext} from 'react';
import {useHistory} from 'react-router';

import {LanguageContext} from '../providers/languageProvider';
import ssyStorage from '../services/ssyStorage';

const useLanguage = () => {
    const {language, setLanguage} = useContext(LanguageContext);
    const history = useHistory()
    const modifyLanguage = (newLanguage: string | null) => {
        if (!newLanguage) {
            newLanguage = ssyStorage.local.getString("locale")
        }
        if (newLanguage && language !== newLanguage && history) {
            const urlChanged = window.location.pathname.replace(`/${language}/`, `/${newLanguage}/`);
            setLanguage(newLanguage);
            history.push(urlChanged);
        }
        if (newLanguage) {
            setLanguage(newLanguage);
        } else {
            setLanguage('en');
        }
    };
    return {
        language,
        modifyLanguage
    };
};

export default useLanguage;
