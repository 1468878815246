import React, {useState} from 'react';

export interface IShowModal {
    active: boolean;
    content: JSX.Element;
    type?: string;
    bgColor?: string;
}

interface IContext {
    showModal: IShowModal;
    setShowModal: React.Dispatch<React.SetStateAction<IShowModal>>;
    resetModal: () => void;
}

const initialState: IContext = {
    showModal: {active: false, content: <React.Fragment/>, type: 'opaque'},
    setShowModal: () => ({}),
    resetModal: () => ({})
};

export const ModalContext = React.createContext(initialState);

export const ModalProvider = (props: any) => {
    const [showModal, setShowModal] = useState<IShowModal>({
        active: false,
        content: <React.Fragment/>,
        type: 'opaque'
    });

    const resetModal = () =>
        setShowModal(current => ({...current, ...{active: false, content: <React.Fragment/>}}));
    return (
        <ModalContext.Provider
            value={{
                showModal,
                setShowModal,
                resetModal
            }}
        >
            {props.children}
        </ModalContext.Provider>
    );
};
