import useNotification from "../../customHooks/useNotification"
import React from 'react';
import {useIntl} from 'react-intl';
import './partnerLinks.scss'

export interface IProps {
    dynamicLink: string,
    linkId: string,
}

const PartnerLink = (props: IProps) => {
    const copyTextToClipboardWithNotification = useNotification().copyTextToClipboardWithNotification
    const intl = useIntl();
    return (
        <div className="linkId">
            <h2>{intl.formatMessage({id: 'LABEL_LINK_ID'})}</h2>
            <div>
                <span className="link">{props.dynamicLink}</span>
                <i className="copy-icon fas fa-copy fa-2x"
                   onClick={() => {
                       copyTextToClipboardWithNotification(props.dynamicLink!, intl.formatMessage({id: 'LABEL_LINK_ID_COPIED'}));
                   }}>
                </i>
            </div>
           
        </div>
    );
};

export default PartnerLink;