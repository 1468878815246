import { IntlShape } from 'react-intl';
import ssyStorage from '../services/ssyStorage';

export const getLanguageFromURL = (pathname: string): string | null => {
    if (
        pathname.indexOf('silentrenew') === -1 &&
        pathname.indexOf('signin-oidc') === -1 &&
        pathname.indexOf('logout') === -1
    ) {
        const language = pathname.split('/')[1] || 'en';
        ssyStorage.local.setString('locale', language);
        return /es|en|de|fr|it|pt|tr/.test(language) ? language : 'en';
    }
    return null;
};

const setCookie = (name: string, value: string, options: any) => {
    options = options || {};

    let expires = options.expires;

    if (typeof expires === 'number' && expires) {
        const d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;
    if (Array.isArray(options)) {
        options.forEach((propName: string) => {
            updatedCookie += '; ' + propName;
            const propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        });
    } else {
        [options].forEach((propName: string) => {
            updatedCookie += '; ' + propName;
            const propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        });
    }

    document.cookie = updatedCookie;
};

export const deleteCookies = (names: string[]) => {
    for (let i = 0; i < names.length; i++) {
        setCookie(names[i], '', {
            expires: -1,
        });
    }
};

interface urlNames {
    REACT_APP_ROOT_URL: string,
    REACT_APP_ROOT_FINANCIAL_URL: string,
    REACT_APP_AUTH_URL: string,
    REACT_APP_REDIRECT_URL: string,
    REACT_APP_URL: string,
    REACT_APP_SILENT_REDIRECT_URL: string,
    REACT_APP_LOGOFF_REDIRECT_URL: string,
    REACT_APP_PUBLIC_URL: string,
    REACT_APP_JOIN_URL: string,
    REACT_APP_IDENTITY_ISSUER: string,
    REACT_APP_IDENTITY_CLIENT_ID: string,
    REACT_APP_SERVER_CONFIGURATION: string,
    REACT_APP_CONTACT_URL: string
}

const configsDomain = [
    {
        appUrl: 'http://localhost:3000',
        mainUrl: 'https://staging-partner.samsaidyes.com',
        public: 'http://localhost:3000',
        auth: 'https://staging-identity.samsaidyes.com',
        env: 'local-to-staging'
    },
    {
        appUrl: "http://localhost:3000",
        mainUrl: "http://localhost:10000",
        public: "http://localhost:3000",
        auth: "https://localhost:7001",
        env: "local"
    },
    {
        appUrl: "https://dev-partner.samsaidyes.com",
        mainUrl: "https://dev-partner.samsaidyes.com",
        public: "https://dev.samsaidyes.com",
        auth: "https://dev-identity.samsaidyes.com",
        env: "dev"
    },
    {
        appUrl: "https://staging-partner.samsaidyes.com",
        mainUrl: "https://staging-partner.samsaidyes.com",
        public: "https://staging.samsaidyes.com",
        auth: "https://staging-identity.samsaidyes.com",
        env: "staging"
    },
    {
        appUrl: "https://partner.samsaidyes.com",
        mainUrl: "https://partner.samsaidyes.com",
        public: "https://samsaidyes.com",
        auth: "https://identity.samsaidyes.com",
        env: "prod"
    }
]

export const getUrls = (url: keyof urlNames) => {
    const envConfig = configsDomain.filter(item => item.appUrl === window.location.origin)[0]

    const urls = {
        REACT_APP_ROOT_URL: `${envConfig.mainUrl}/api`,
        REACT_APP_ROOT_FINANCIAL_URL: `${envConfig.mainUrl}/api`,
        REACT_APP_AUTH_URL: envConfig.auth,
        REACT_APP_URL: envConfig.appUrl,
        REACT_APP_REDIRECT_URL: `${envConfig.appUrl}/signin-oidc`,
        REACT_APP_SILENT_REDIRECT_URL: `${envConfig.appUrl}/silentrenew`,
        REACT_APP_LOGOFF_REDIRECT_URL: `${envConfig.appUrl}/logout/callback`,
        REACT_APP_PUBLIC_URL: envConfig.public,
        REACT_APP_JOIN_URL: `${envConfig.mainUrl}/join`,
        REACT_APP_IDENTITY_ISSUER: envConfig.auth.includes("localhost") ? envConfig.auth : "http://identityserver",
        REACT_APP_IDENTITY_CLIENT_ID: "PartnerWebApplication",
        REACT_APP_SERVER_CONFIGURATION: "DEBUG",
        REACT_APP_CONTACT_URL: "https://help.samsaidyes.com/hc"
    }

    return urls[url];
}

export const isMobile = () => window.innerWidth < 768;
export const isTablet = () => window.innerWidth < 1025 && window.innerWidth >= 768;
export const isDesktop = () => window.innerWidth >= 1025;

export const validateEmail = (text: string, intl: any) => {
    const emailEvaluation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailEvaluation.test(text)) {
        return intl.formatMessage({id: 'INVALID_EMAIL_TEXT'});
    } else {
        return '';
    }
};

export const validatePassword = (text: string, intl: IntlShape): string => {
    if ((text && text.length < 6) || text === '') {
        return intl.formatMessage({id: 'INVALID_PASSWORD_TEXT'});
    }
    return '';
};

export const validateWebsite = (text: string, intl: IntlShape): string => {
    const webisteEvaluation = /([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/
    if (!webisteEvaluation.test(text)) {
        return intl.formatMessage({id: 'INVALID_WEBSITE_TEXT'})
    }
    return ''
}

export const validateStringLength = (text: string, minChars: number, intl: IntlShape): string => {
    if (!!text && text.length >= minChars) {
        return ''
    } else {
        return intl.formatMessage({id: "INVALID_STRING_LENGHT"})
    }
}


