import * as serviceWorker from './serviceWorker';
import * as React from 'react';
import ReactDOM from 'react-dom';
import {App} from "./app"
import './assets/css/main.scss';
import './assets/css/font-awesome.scss';
import {LanguageProvider} from './providers/languageProvider';


ReactDOM.render(
    <LanguageProvider>
        <App/>
    </LanguageProvider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
