// TODO: use useReucer istead of useState

import React, {useState} from 'react';
import {RaisingBox} from '../../components/raisingBox/raisingBox';
import {InputText} from '../../components/inputText/inputText';
import {IRegisterPartnerRequest, IRegisterPartnerResponse, PartnerCategory} from '../../model/partnerModels';
import useLanguage from '../../customHooks/useLanguage';
import {SsySlider} from '../../components/ssySlider/ssySlider';
import {useIntl} from 'react-intl';
import {SsyButton} from '../../components/ssyButton/ssyButton';
import SsySelect from '../../components/ssySelectInput/ssySelectInput';
import {PartnerService} from '../../services/partnerService';
import InputPassword from '../../components/inputPassword/inputPassword';
import {InputCheckbox} from '../../components/inputCheckbox/inputCheckbox';
import {getUrls, validateEmail, validatePassword, validateWebsite} from '../../utils/helpers';
import {useHistory} from 'react-router';
import './registerPage.scss';
import {useAuth} from '../../customHooks/useAuth';
import {CATEGORY_OPTIONS, NUMBER_EVENTS_OPTIONS} from '../../utils/const';

export const RegisterPage = () => {
    const {createSigninRequest} = useAuth()

    //Fields
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [eventsPerYear, setEventsPerYear] = useState('');
    const [category, setCategory] = useState<PartnerCategory>(PartnerCategory.others);
    const [termsAccepted, setTermsAccepted] = useState(false);

    //Errors
    const [websiteError, setWebsiteError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [termsAcceptedError, setTermsAcceptedError] = useState('');
    const [eventsPerYearError, setEventsPerYearError] = useState('');

    const [blockSlides, setBlockSlides] = useState(true);
    const {language} = useLanguage();
    const history = useHistory();
    const intl = useIntl();

    const bannerItems = ['REGISTER_BANNER_ITEM_1', 'REGISTER_BANNER_ITEM_2', 'REGISTER_BANNER_ITEM_3'];

    const validateFirstForm = (): boolean => {
        let isValid = true;

        if (!!website) {
            const webErr = validateWebsite(website, intl);
            isValid = webErr === '' ? isValid : false;
            setWebsiteError(webErr);
        } else {
            setWebsiteError('');
        }

        if (!eventsPerYear) {
            isValid = false;
            setEventsPerYearError(intl.formatMessage({id: 'INVALID_EVENTS_PER_YEAR'}));
        } else {
            setEventsPerYearError('');
        }

        return isValid;
    };

    const validateSecondForm = (): boolean => {
        let isValid = true;

        isValid = name !== '' ? isValid : false;
        setNameError(name === '' ? intl.formatMessage({id: 'INVALID_NAME'}) : '');

        const emailErr = validateEmail(email, intl);
        isValid = emailErr === '' ? isValid : false;
        setEmailError(emailErr);

        const passErr = validatePassword(password, intl);
        isValid = passErr === '' ? isValid : false;
        setPasswordError(passErr);

        if (!termsAccepted) {
            isValid = false;
            setTermsAcceptedError(intl.formatMessage({id: 'TERMS_NOT_ACCEPTED'}));
        } else {
            setTermsAcceptedError('');
        }

        return isValid;
    };

    const onSubmit = (createSigninRequest: any) => {
        const requestPayload: IRegisterPartnerRequest = {
            website,
            password,
            email,
            name,
            eventsPerYear,
            category,
            language
        };

        if (validateFirstForm() && validateSecondForm()) {
            PartnerService()
                .register(requestPayload)
                .then(result => {
                    createSigninRequest({}).then((signinRequest: any) => {
                        autoLogin(signinRequest, result);
                    });
                })
                .catch(e => {
                    if (e.status === 409) {
                        setEmailError(intl.formatMessage({id: 'REGISTER_EMAIL_EXISTS'}));
                    }
                });
        } else {
            return;
        }
    };

    const autoLogin = (signinRequest: any, userRegisterResult: IRegisterPartnerResponse) => {
        const el = document.createElement('a');
        el.href = signinRequest.url;
        const encodeReturnUrl = encodeURIComponent(el.pathname + el.search);
        const autoLoginUrl =
            getUrls("REACT_APP_AUTH_URL") +
            '/account/autologin?userId=' +
            userRegisterResult.Id +
            '&token=' +
            encodeURIComponent(userRegisterResult.autologin_token) +
            '&ReturnUrl=' +
            encodeReturnUrl +
            '&culture=' +
            intl.locale;
        if(window.top){
            window.top.location.href = autoLoginUrl;    
        }
    };

    const onSlideChange = (nextIndex: number) => {
        if (nextIndex === 1) {
            setBlockSlides(false);
        } else if (nextIndex === 0) {
            setBlockSlides(true);
        }
    };

    return (
        <div id="registerPage">
            <div className="registerContainer">
                <div className="registerForm">
                    <RaisingBox quantityOfItems={5} delayOrder={1}>
                        <h1>{intl.formatMessage({id: 'REGISTER_FORM_TITLE'})}</h1>
                    </RaisingBox>
                    <SsySlider
                        blockBullets={blockSlides}
                        blockScroll={blockSlides}
                        onSlideChange={onSlideChange}
                        slidesClassname="registrationTab"
                        containerClassName="registerSlider"
                        bulletsClassname="registerBullets"
                    >
                        {setSliderPosition => [
                            <>
                                <RaisingBox quantityOfItems={5} delayOrder={2}>
                                    <SsySelect
                                        containerClassName="registerInputText"
                                        value={category}
                                        onChange={(e: any) => {
                                            setCategory(e.target.value);
                                        }}
                                        options={CATEGORY_OPTIONS}
                                        intl={intl}
                                        label={'LABEL_CATEGORY'}
                                    />
                                </RaisingBox>
                                <RaisingBox quantityOfItems={5} delayOrder={3}>
                                    <InputText
                                        containerClassName="registerInputText"
                                        value={website}
                                        onChange={e => setWebsite(e.target.value)}
                                        label={intl.formatMessage({id: 'LABEL_YOUR_WEBSITE'})}
                                        errorMessage={websiteError}
                                    />
                                </RaisingBox>
                                <RaisingBox quantityOfItems={5} delayOrder={4}>
                                    <SsySelect
                                        name="numberOfEvents"
                                        containerClassName="registerInputText"
                                        value={eventsPerYear}
                                        label={'LABEL_EVENTS_PER_YEAR'}
                                        onChange={(e: any) => {
                                            setEventsPerYear(e.target.value);
                                        }}
                                        errorMessage={eventsPerYearError}
                                        options={NUMBER_EVENTS_OPTIONS}
                                        intl={intl}
                                    />
                                </RaisingBox>
                                <RaisingBox quantityOfItems={5} delayOrder={5}>
                                    <SsyButton
                                        type="nonBorder full"
                                        label={intl.formatMessage({id: 'LABEL_CONTINUE'})}
                                        onClick={() => {
                                            if (validateFirstForm()) {
                                                setSliderPosition(1);
                                            }
                                        }}
                                    />
                                </RaisingBox>
                            </>,
                            <>
                                <InputText
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    containerClassName="registerInputText"
                                    label={intl.formatMessage({id: 'LABEL_YOUR_NAME'})}
                                    errorMessage={nameError}
                                />
                                <InputText
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    containerClassName="registerInputText"
                                    label={intl.formatMessage({id: 'LABEL_EMAIL'})}
                                    errorMessage={emailError}
                                />
                                <InputPassword
                                    value={password}
                                    name="password"
                                    strenthEvaluable={true}
                                    onChange={e => setPassword(e.target.value)}
                                    containerClassName="registerInputText"
                                    label={intl.formatMessage({id: 'LABEL_SET_PASSWORD'})}
                                    errorMessage={passwordError}
                                />
                                <InputCheckbox
                                    containerClassName="registerCheckbox"
                                    text={intl.formatMessage({id: 'REGISTER_TERMS_OR_SERVICE'})}
                                    isChecked={termsAccepted}
                                    onClick={() => setTermsAccepted(prev => !prev)}
                                    errorMessage={termsAcceptedError}
                                />
                                <SsyButton
                                    type="nonBorder"
                                    label={intl.formatMessage({id: 'LABEL_CREATE_ACCOUNT'})}
                                    onClick={() => onSubmit(createSigninRequest)}
                                />
                            </>
                        ]}
                    </SsySlider>
                </div>
            </div>
            <div className="bannerContainer">
                <div className="advantages">
                    <RaisingBox quantityOfItems={4} delayOrder={1} type="header">
                        <h2>{intl.formatMessage({id: 'REGISTER_BANNER_TITLE'})}</h2>
                    </RaisingBox>
                    {bannerItems.map((item, index) => (
                        <RaisingBox
                            key={index}
                            quantityOfItems={bannerItems.length + 1}
                            delayOrder={index + 1}
                            type="bannerItem"
                        >
                            <i className="fal fa-check-circle"/>
                            <span>{intl.formatMessage({id: item})}</span>
                        </RaisingBox>
                    ))}
                </div>
            </div>
        </div>
    );
};
